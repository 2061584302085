.front-page .eyecatch {
  position: relative;
  background-color: transparent;
}

.front-page .eyecatch::before {
  content: "";
  display: block;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.4);
  position: absolute;
  top: 0;
  left: 0;
  z-index: 2;
}

.front-page .eyecatch__video {
  width: 100%;
  height: 100%;
  object-fit: cover;
  position: absolute;
  top: 0;
  left: 0;
}

.front-page .eyecatch .wrapper {
  height: 100%;
  position: relative;
}

.front-page .eyecatch__container {
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.front-page .eyecatch .eyecatch__ttl,
.front-page .eyecatch .eyecatch__sub {
  position: static;
}

.front-page .eyecatch__sub {
  margin: 30px 0;
}

.front-page .eyecatch .eyecatch__btn {
  width: 200px;
  position: relative;
  top: 0;
  left: 0;
}

.front-page .eyecatch .eyecatch__btn::after {
  top: 9px;
}

.front-page .eyecatch .eyecatch__btn:hover .eyecatch__btn__eff {
  left: 100%;
}

.front-page .service {
  margin-top: 100px;
}

.greeting#greeting {
  padding: 120px 0;
}

@media screen and (max-width: 768px) {
  .greeting#greeting {
    padding: 50px 0;
  }
}

.greeting__container {
  display: flex;
  justify-content: space-between;
}

@media screen and (max-width: 768px) {
  .greeting__container {
    display: block;
  }
}

.greeting__img {
  max-width: 250px;
  width: 100%;
  margin-right: 20px;
}

.greeting__img img {
  height: auto;
}

.greeting__txt {
  width: calc(100% - 270px);
}

@media screen and (max-width: 768px) {
  .greeting__txt {
    width: 100%;
    margin-top: 30px;
  }
}

.greeting__title {
  font-size: 2.4rem;
  margin-bottom: 15px;
  color: #000;
  font-family: "YuMincho", "Hiragino Mincho ProN", "Yu Mincho", "MS PMincho", "Times New Roman", serif;
  font-weight: 400;
}

@media screen and (max-width: 768px) {
  .greeting__title {
    font-size: 2rem;
  }
}

.overview-ta td {
  background-color: #fff;
  border: 1px solid #f8f8f8 !important;
}

.overview__map iframe {
  width: 100%;
  height: 250px;
}

.child-links__greeting {
  background-image: url(../img/greeting/img-ceo.jpg) !important;
  background-position: center 20% !important;
}

.service-lead {
  padding: 100px 0;
}

.service-lead__container {
  display: flex;
  justify-content: space-between;
}

@media screen and (max-width: 768px) {
  .service-lead__container {
    display: block;
  }
}

.service-lead__txt {
  width: 50%;
}

@media screen and (max-width: 768px) {
  .service-lead__txt {
    width: 100%;
  }
}

.service-lead__txt h2 {
  font-size: 2.4rem;
  margin-bottom: 15px;
  color: #000;
  font-family: "YuMincho", "Hiragino Mincho ProN", "Yu Mincho", "MS PMincho", "Times New Roman", serif;
  font-weight: 400;
}

@media screen and (max-width: 768px) {
  .service-lead__txt h2 {
    font-size: 2rem;
  }
}

.service-lead__txt p {
  font-size: 1.6rem;
  line-height: 1.8;
}

.service-lead__txt p span {
  color: #1C58C2;
  font-weight: 600;
  font-family: "YuMincho", "Hiragino Mincho ProN", "Yu Mincho", "MS PMincho", "Times New Roman", serif;
}

.service-lead__slider {
  width: 45%;
  position: relative;
}

@media screen and (max-width: 768px) {
  .service-lead__slider {
    width: 100%;
    margin-top: 20px;
  }
}

.service-lead__slider .swiper-pagination {
  bottom: -20px;
}

.service-lead__slider .swiper-pagination .swiper-pagination-bullet-active {
  background-color: #1C58C2;
}

.service-lead__slider .swiper-slide {
  width: 100%;
  height: 100%;
}

.service-lead__slider .swiper-slide img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.sub-title {
  margin-bottom: 15px;
}

.sub-title span {
  display: inline-block;
}

.sub-title span.en {
  width: 100%;
  color: #1C58C2;
  font-size: 1.2rem;
  letter-spacing: .02em;
  font-family: 'Oswald', sans-serif;
}

.sub-title span.ja {
  margin-top: -5px;
  font-size: 2.4rem;
  color: #000;
  font-weight: 600;
  background-color: #1C58C2;
  color: #fff;
  padding: 2px 5px;
}

@media screen and (max-width: 768px) {
  .sub-title span.ja {
    font-size: 2rem;
  }
}

.service-list {
  margin-top: 50px;
}

.service-list__block {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

@media screen and (max-width: 768px) {
  .service-list__block {
    display: block;
  }
}

.service-list__block:nth-child(2n) {
  flex-direction: row-reverse;
  margin-top: 100px;
}

.service-list__block--txt {
  width: 50%;
}

@media screen and (max-width: 768px) {
  .service-list__block--txt {
    width: 100%;
    margin-top: 30px;
  }
}

.service-list__block--txt p {
  font-size: 1.6rem;
  line-height: 1.8;
}

.service-list__block--slider {
  width: 45%;
  position: relative;
}

@media screen and (max-width: 768px) {
  .service-list__block--slider {
    width: 100%;
    margin-top: 20px;
  }
}

.service-list__block--slider .swiper-pagination {
  bottom: -20px;
}

.service-list__block--slider .swiper-pagination .swiper-pagination-bullet-active {
  background-color: #1C58C2;
}

.service-list__block--slider .swiper-slide {
  width: 100%;
  height: 100%;
}

.service-list__block--slider .swiper-slide img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.top-philosophy {
  min-height: 100vh;
  padding: 100px 0;
  display: flex;
  align-items: center;
  justify-content: center;
}

@media screen and (max-width: 768px) {
  .top-philosophy {
    display: block;
    padding-bottom: 0;
  }
}

.top-philosophy .top-sec__ttl {
  margin-top: 0;
  width: 100%;
  margin-bottom: 50px;
}

.top-philosophy .top-sec__ttl h2, .top-philosophy .top-sec__ttl p {
  color: #000;
}

.top-philosophy__container {
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-direction: row-reverse;
}

@media screen and (max-width: 768px) {
  .top-philosophy__container {
    display: block;
  }
}

.top-philosophy__img {
  width: 50%;
  position: relative;
}

@media screen and (max-width: 768px) {
  .top-philosophy__img {
    width: 100%;
  }
}

.top-philosophy__img.is-active::before {
  width: 0;
}

.top-philosophy__img::before {
  content: "";
  display: block;
  width: 100%;
  height: 100%;
  background-color: #1C58C2;
  transition: all .3s ease-out;
  position: absolute;
  top: 0;
  left: 0%;
}

.top-philosophy__img img {
  height: auto;
}

.top-philosophy__txt {
  width: 40%;
  opacity: 0;
  transform: translateY(50px);
  transition: .8s;
}

@media screen and (max-width: 768px) {
  .top-philosophy__txt {
    width: 100%;
    margin-top: 30px;
  }
}

.top-philosophy__txt.is-active {
  opacity: 1;
  transform: translateY(0);
}

.top-philosophy__txt h3 {
  font-size: 2.4rem;
  font-family: "YuMincho", "Hiragino Mincho ProN", "Yu Mincho", "MS PMincho", "Times New Roman", serif;
  font-weight: 400;
  margin-bottom: 30px;
}

@media screen and (max-width: 768px) {
  .top-philosophy__txt h3 {
    font-size: 2rem;
  }
}

.top-philosophy__txt p {
  line-height: 2;
  font-size: 2rem;
}

@media screen and (max-width: 768px) {
  .top-philosophy__txt p {
    font-size: 1.6rem;
  }
}

.top-philosophy__txt .eyecatch__btn {
  position: relative;
  margin-top: 30px;
  max-width: 200px;
}

.sticky-container {
  min-height: 300vh;
}

.three-philosophy {
  padding: 100px 0;
}

.three-philosophy__title {
  color: #1C58C2;
  font-size: 3rem;
  text-align: center;
  margin-bottom: 50px;
  font-family: "YuMincho", "Hiragino Mincho ProN", "Yu Mincho", "MS PMincho", "Times New Roman", serif;
  font-weight: 400;
}

@media screen and (max-width: 768px) {
  .three-philosophy__title {
    font-size: 2.2rem;
  }
}

.three-philosophy__container {
  width: 100%;
  height: 100vh;
  min-height: 500px;
  background-color: #fff;
  display: flex;
  justify-content: space-between;
  align-items: center;
  position: sticky;
  top: 0;
}

@media screen and (max-width: 768px) {
  .three-philosophy__container {
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
  }
}

.three-philosophy__container.one {
  z-index: 1;
}

.three-philosophy__container.two {
  z-index: 2;
}

.three-philosophy__container.three {
  z-index: 3;
}

.three-philosophy__desc {
  width: 50%;
}

@media screen and (max-width: 768px) {
  .three-philosophy__desc {
    width: 100%;
    margin-top: 30px;
  }
}

.three-philosophy__desc h4 {
  font-family: "YuMincho", "Hiragino Mincho ProN", "Yu Mincho", "MS PMincho", "Times New Roman", serif;
  font-size: 2.4rem;
  font-weight: 400;
  margin-bottom: 30px;
}

@media screen and (max-width: 768px) {
  .three-philosophy__desc h4 {
    font-size: 2rem;
  }
}

.three-philosophy__desc p {
  font-size: 1.6rem;
  line-height: 2;
}

@media screen and (max-width: 768px) {
  .three-philosophy__desc p {
    font-size: 1.4rem;
  }
}
