.front-page {
    .eyecatch {
        position: relative;
        background-color: transparent;
        &::before {
            content: "";
            display: block;
            width: 100%;
            height: 100%;
            background-color: rgba(#000, .4);
            position: absolute;
            top: 0;
            left: 0;
            z-index: 2;
        }
        &__video {
            width: 100%;
            height: 100%;
            object-fit: cover;
            position: absolute;
            top: 0;
            left: 0;
        }
        .wrapper {
            height: 100%;
            position: relative;
        }
        &__container {
            height: 100%;
            display: flex;
            flex-direction: column;
            justify-content: center;
        }
        .eyecatch__ttl,
        .eyecatch__sub {
            position: static;
        }
        &__sub {
            margin: 30px 0;
        }

        .eyecatch__btn {
            width: 200px;
            position: relative;
            top: 0;
            left: 0;
            &::after {
                top: 9px;
            }
            &:hover {
                .eyecatch__btn__eff {
                    left: 100%;
                }
            }
        }
    }
    
    .service {
        margin-top: 100px;
    }
}

.greeting {
    &#greeting {
        padding: 120px 0;
        @media screen and (max-width: 768px) {
            padding: 50px 0;
        }
    }
    &__container {
        display: flex;
        justify-content: space-between;
        @media screen and (max-width: 768px) {
            display: block;
        }
    }
    &__img {
        max-width: 250px;
        width: 100%;
        margin-right: 20px;
        img {
            height: auto;
        }
    }
    &__txt {
        width: calc(100% - 270px);
        @media screen and (max-width: 768px) {
            width: 100%;
            margin-top: 30px;
        }
    }
    &__title {
        font-size: 2.4rem;
        margin-bottom: 15px;
        color: #000;
        font-family: "YuMincho", "Hiragino Mincho ProN", "Yu Mincho", "MS PMincho", "Times New Roman", serif;
        font-weight: 400;
        @media screen and (max-width: 768px) {
            font-size: 2rem;
        }
    }
}

.overview {
    &-ta {
        td {
            background-color: #fff;
            border: 1px solid #f8f8f8 !important;
        }
    }
    &__map {
        iframe {
            width: 100%;
            height: 250px;
        }
    }
}

.child-links__greeting {
    background-image: url(../img/greeting/img-ceo.jpg) !important;
    background-position: center 20% !important;
}

.service-lead {
    padding: 100px 0;
    &__container {
        display: flex;
        justify-content: space-between;
        @media screen and (max-width: 768px) {
            display: block;
        }
    }
    &__txt {
        width: 50%;
        @media screen and (max-width: 768px) {
            width: 100%;
        }
        h2 {
            font-size: 2.4rem;
            margin-bottom: 15px;
            color: #000;
            font-family: "YuMincho", "Hiragino Mincho ProN", "Yu Mincho", "MS PMincho", "Times New Roman", serif;
            font-weight: 400;
            @media screen and (max-width: 768px) {
                font-size: 2rem;
            }
        }
        p {
            font-size: 1.6rem;
            line-height: 1.8;
            span {
                color: #1C58C2;
                font-weight: 600;
                font-family: "YuMincho", "Hiragino Mincho ProN", "Yu Mincho", "MS PMincho", "Times New Roman", serif;
            }
        }
    }
    &__slider {
        width: 45%;
        position: relative;
        @media screen and (max-width: 768px) {
            width: 100%;
            margin-top: 20px;
        }
        .swiper-pagination {
            bottom: -20px;
            .swiper-pagination-bullet-active {
                background-color: #1C58C2;
            }
        }
        .swiper-slide {
            width: 100%;
            height: 100%;
            img {
                width: 100%;
                height: 100%;
                object-fit: cover;
            }
        }
    }
}

.sub-title {
    margin-bottom: 15px;
    span {
        display: inline-block;
        &.en {
            width: 100%;
            color: #1C58C2;
            font-size: 1.2rem;
            letter-spacing: .02em;
            font-family: 'Oswald', sans-serif;
        }
        &.ja {
            margin-top: -5px;
            font-size: 2.4rem;
            color: #000;
            font-weight: 600;
            background-color: #1C58C2;
            color: #fff;
            padding: 2px 5px;
            @media screen and (max-width: 768px) {
                font-size: 2rem;
            }
        }
    }
}

.service-list {
    margin-top: 50px;
    &__block {
        display: flex;
        justify-content: space-between;
        align-items: center;
        @media screen and (max-width: 768px) {
            display: block;
        }
        &:nth-child(2n) {
            flex-direction: row-reverse;
            margin-top: 100px;
        }
        &--txt {
            width: 50%;
            @media screen and (max-width: 768px) {
                width: 100%;
                margin-top: 30px;
            }
            p {
                font-size: 1.6rem;
                line-height: 1.8;
            }
        }
        &--slider {
            width: 45%;
            position: relative;
            @media screen and (max-width: 768px) {
                width: 100%;
                margin-top: 20px;
            }
            .swiper-pagination {
                bottom: -20px;
                .swiper-pagination-bullet-active {
                    background-color: #1C58C2;
                }
            }
            .swiper-slide {
                width: 100%;
                height: 100%;
                img {
                    width: 100%;
                    height: 100%;
                    object-fit: cover;
                }
            }
        }
    }
}

.top-philosophy {
    min-height: 100vh;
    padding: 100px 0;
    display: flex;
    align-items: center;
    justify-content: center;
    @media screen and (max-width: 768px) {
        display: block;
        padding-bottom: 0;
    }
    .top-sec__ttl {
        margin-top: 0;
        width: 100%;
        margin-bottom: 50px;
        h2,p {
            color: #000;
        }
    }
    &__container {
        display: flex;
        justify-content: space-between;
        align-items: center;
        flex-direction: row-reverse;
        @media screen and (max-width: 768px) {
            display: block;
        }
    }
    &__img {
        width: 50%;
        position: relative;
        @media screen and (max-width: 768px) {
            width: 100%;
        }
        &.is-active {
            &::before {
                width: 0;
            }
        }
        &::before {
            content: "";
            display: block;
            width: 100%;
            height: 100%;
            background-color: #1C58C2;
            transition: all .3s ease-out;
            position: absolute;
            top: 0;
            left: 0%;
        }
        img {
            height: auto;
        }
    }
    &__txt {
        width: 40%;
        opacity: 0;
        transform: translateY(50px);
        transition: .8s;
        @media screen and (max-width: 768px) {
            width: 100%;
            margin-top: 30px;
        }
        &.is-active {
            opacity: 1;
            transform: translateY(0);
        }
        h3 {
            font-size: 2.4rem;
            font-family: "YuMincho", "Hiragino Mincho ProN", "Yu Mincho", "MS PMincho", "Times New Roman", serif;
            font-weight: 400;
            margin-bottom: 30px;
            @media screen and (max-width: 768px) {
                font-size: 2rem;
            }
        }

        p {
            line-height: 2;
            font-size: 2rem;
            @media screen and (max-width: 768px) {
                font-size: 1.6rem;
            }
        }

        .eyecatch__btn {
            position: relative;
            margin-top: 30px;
            max-width: 200px;
        }
    }
}

.sticky-container {
    min-height: 300vh;
}

.three-philosophy {
    padding: 100px 0;
    &__title {
        color: #1C58C2;
        font-size: 3rem;
        text-align: center;
        margin-bottom: 50px;
        font-family: "YuMincho", "Hiragino Mincho ProN", "Yu Mincho", "MS PMincho", "Times New Roman", serif;
        font-weight: 400;
        @media screen and (max-width: 768px) {
            font-size: 2.2rem;
        }
    }
    &__container {
        width: 100%;
        height: 100vh;
        min-height: 500px;
        background-color: #fff;
        display: flex;
        justify-content: space-between;
        align-items: center;
        position: sticky;
        top: 0;
        @media screen and (max-width: 768px) {
            flex-direction: column;
            justify-content: center;
            align-items: flex-start;
        }
        &.one {
            z-index: 1;
        }
        &.two {
            z-index: 2;
        }
        &.three {
            z-index: 3;
        }
    }
    &__desc {
        width: 50%;
        @media screen and (max-width: 768px) {
            width: 100%;
            margin-top: 30px;
        }
        h4 {
            font-family: "YuMincho", "Hiragino Mincho ProN", "Yu Mincho", "MS PMincho", "Times New Roman", serif;
            font-size: 2.4rem;
            font-weight: 400;
            margin-bottom: 30px;
            @media screen and (max-width: 768px) {
                font-size: 2rem;
            }
        }
        p {
            font-size: 1.6rem;
            line-height: 2;
            @media screen and (max-width: 768px) {
                font-size: 1.4rem;
            }
        }
    }
}